import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SSRProvider from 'react-bootstrap/SSRProvider'
import ImageZoom from '../../libs/image-zoom'

import AboutSideMenu from '../components/aboutSideMenu'
import BasicLearnMorePanel from '../components/basicLearnMorePanel'
import Celebrating30Years from '../components/celebrating30Years'
import ContentQuoteCenter from "../components/contentQuoteCenter.js"
import DefaultHeader from '../components/default-header'
import Footer from '../components/footer'
import GdprPanel from '../components/gdpr'
import JHVideos from "../components/jeanHoustonVideos"
import Navigation from '../components/navigation'
import Seo from '../components/seo'
import NewsletterSignup from '../components/newsletterSignUp.js'

import '../styles/about.scss'

export default function AboutPage({ data }) {

    const post = data.mdx
    const shortcodes = {
        ContentQuoteCenter,
        JHVideos,
        ImageZoom,
        Row,
        Col,
      }

    return (      
        <> 
            <SSRProvider>
                <Navigation />
                <DefaultHeader headline={post.frontmatter.headline} heroBackground={post.frontmatter.heroBackground} heroMobileBackground={post.frontmatter.heroMobileBackground} subHeadline={post.frontmatter.subheadline} />
                <div id="body">
                    <Container fluid="lg">
                        <Row>
                            <Col xl={9} md={8} className="pe-md-5 mb-md-5 mb-3">
                                <MDXProvider components={shortcodes}>
                                    <MDXRenderer>{post.body}</MDXRenderer>
                                </MDXProvider>      
                            </Col>
                            <Col xl={3} md={4}>
                                <AboutSideMenu />
                                <BasicLearnMorePanel menuType="basic" />
                            </Col>
                        </Row>
                        <Celebrating30Years />
                    </Container>
                </div>
                <Footer />
                <GdprPanel />
                <NewsletterSignup />
            </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage} 
    />
)

export const query = graphql`
    query($slug: String!) {
        mdx(slug: { eq: $slug }) {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                metadata {
                    Description
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }   
`
